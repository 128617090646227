import React, { ReactElement, ReactNode } from 'react';

import NiceModal from '@ebay/nice-modal-react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';

import { generateAssetsObject } from './configureAssets';
import { HelpWizardButtonOverlay, HelpWizardProvider } from '../components/rn';
import {
  Account,
  Config,
  ConfigContextProvider,
  ContractsProvider,
  Customer,
  DataCacheState,
  UserContextProvider,
  UserSettingsContextProvider,
  useUserContext,
} from '../contexts';
import { VkwAssetsContextProvider, VkwDateIo, VkwIntlContextProvider } from '../library';
import messages from '../messages';
import { getTheme } from '../themes';
import { EnvFeatureFlags, EnvUrls, UserPanelLinks } from '../util/helpers';

interface InternalProvidersProps {
  project: string;
  envUrls: EnvUrls;
  locale: string;
  children: ReactNode;
}

interface ProvidersProps {
  project: string;
  envUrls: EnvUrls;
  userPanelLinks: UserPanelLinks | null;
  config: Config;
  featureFlags: EnvFeatureFlags;
  children: ReactNode;
  account: Account | null;
  customers: Customer[];
  selectedCustomer: Customer | null;
  googleMapsApiKey: string;
  backofficeUrl: string | null;
  dataCacheState: DataCacheState | null;
}

const InternalProviders = ({ children, envUrls, locale, project }: InternalProvidersProps): ReactElement => {
  const userContext = useUserContext();
  const theme = getTheme(userContext.account?.info.theme || 'default', project);

  return (
    <ThemeProvider theme={theme}>
      <VkwAssetsContextProvider
        project={project}
        generateAssetsObject={(proj, mode) => generateAssetsObject(proj, envUrls, mode, theme)}
      >
        <CssBaseline />
        <MuiPickersUtilsProvider utils={VkwDateIo} locale={locale}>
          {children}
        </MuiPickersUtilsProvider>
      </VkwAssetsContextProvider>
    </ThemeProvider>
  );
};

export const Providers = ({
  account,
  backofficeUrl,
  children,
  config,
  customers,
  dataCacheState,
  envUrls,
  featureFlags,
  googleMapsApiKey,
  project,
  selectedCustomer,
  userPanelLinks,
}: ProvidersProps): ReactElement => {
  const locale = 'de';

  return (
    <VkwIntlContextProvider initLocale={locale} additionalMessages={messages}>
      <HelpWizardProvider>
        <SnackbarProvider>
          <ConfigContextProvider
            config={config}
            featureFlags={featureFlags}
            envUrls={envUrls}
            userPanelLinks={userPanelLinks}
            googleMapsApiKey={googleMapsApiKey}
            backofficeUrl={backofficeUrl}
          >
            <UserContextProvider
              account={account}
              customers={customers}
              selectedCustomer={selectedCustomer}
              dataCacheState={dataCacheState}
            >
              <UserSettingsContextProvider>
                <ContractsProvider>
                  <InternalProviders project={project} envUrls={envUrls} locale={locale}>
                    <NiceModal.Provider>{children}</NiceModal.Provider>
                    <HelpWizardButtonOverlay />
                  </InternalProviders>
                </ContractsProvider>
              </UserSettingsContextProvider>
            </UserContextProvider>
          </ConfigContextProvider>
        </SnackbarProvider>
      </HelpWizardProvider>
    </VkwIntlContextProvider>
  );
};
