export enum InvoiceSource {
  EMP = 'EMP',
  CPO = 'CPO',
}

export type InvoiceStatus =
  | 'TRANSFER_PENDING'
  | 'INVOICING_PENDING'
  | 'INVOICE_CREATED'
  | 'INVOICE_CANCELLED'
  | 'INVOICE_UPDATED'
  | 'TRANSACTION_CANCELLED';

export interface Invoice {
  billing: {
    date: Date;
    periodFrom: Date;
    periodTo: Date;
  };
  contract: {
    id: string;
    type: string | null;
  };
  product: null | {
    id: string;
    name: string;
  };
  id: string;
  customerId: string;
  status: InvoiceStatus;
  source: InvoiceSource;
  evcoId: string | null;
  currency: string | null;
  date: Date;
  externalUrl: string | null;
  fileToDownload: boolean;
  grossAmount: number;
  netAmount: number;
  reference: string;
}
