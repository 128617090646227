import { evnDarkTheme } from './evnDarkTheme';
import { evnTheme } from './evnTheme';
import { evoDarkTheme } from './evoDarkTheme';
import { evoTheme } from './evoTheme';
import { ikbDarkTheme } from './ikbDarkTheme';
import { ikbTheme } from './ikbTheme';
import { lagDarkTheme } from './lagDarkTheme';
import { lagTheme } from './lagTheme';
import { moonDarkTheme } from './moonDarkTheme';
import { moonTheme } from './moonTheme';
import { vkwDarkTheme } from './vkwDarkTheme';
import { vkwTheme } from './vkwTheme';
import { VkwTheme } from '../library';

export type ThemeMode = 'default' | 'dark' | 'light';

export const getDefaultThemeMode = (project: string): 'light' | 'dark' => {
  switch (project) {
    case 'moon':
      return 'dark';
    default:
      return 'light';
  }
};

export const getDarkTheme = (project: string): VkwTheme => {
  switch (project) {
    case 'evn':
      return evnDarkTheme;
    case 'evo':
      return evoDarkTheme;
    case 'ikb':
      return ikbDarkTheme;
    case 'lag':
      return lagDarkTheme;
    case 'moon':
      return moonDarkTheme;
    default:
      return vkwDarkTheme;
  }
};

export const getLightTheme = (project: string): VkwTheme => {
  switch (project) {
    case 'evn':
      return evnTheme;
    case 'evo':
      return evoTheme;
    case 'ikb':
      return ikbTheme;
    case 'lag':
      return lagTheme;
    case 'moon':
      return moonTheme;
    default:
      return vkwTheme;
  }
};

export const getTheme = (mode: ThemeMode, project = 'vkw'): VkwTheme => {
  const selectedMode = mode !== 'default' ? mode : getDefaultThemeMode(project);

  return selectedMode === 'light' ? getLightTheme(project) : getDarkTheme(project);
};
