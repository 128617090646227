import React, { ReactElement, ReactNode } from 'react';

import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { Box, Typography } from '@material-ui/core';
import Link from 'next/link';

import { useVkwTheme } from '../../themes';

export interface VkwHeaderProps {
  title: string;
  /**
   * Render Prop um rechtsbündig zusätzliche Elemente zu rendern
   */
  renderSubTitle?: () => ReactNode;
  /**
   * Wird dies übergeben so erscheint über der Überschrift ein CallToAction Link
   */
  backLink?: {
    text: string;
    href: string;
  };
}

export const VkwHeader = ({ backLink, renderSubTitle, title }: VkwHeaderProps): ReactElement => {
  const theme = useVkwTheme();

  return (
    <>
      <Box height="1.5rem" maxWidth="100%">
        {backLink && (
          <Link href={backLink.href} className="flex items-center font-semibold">
            <ChevronLeftIcon className="mr-1 h-4 w-4" /> {backLink.text}
          </Link>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom={3}
        marginBottom={5}
        borderBottom={`1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`}
      >
        <Box maxWidth="100%">
          <Typography color={theme.palette.headerColor} variant="h2" component="h1" noWrap>
            {title}
          </Typography>
        </Box>
        {renderSubTitle && <Box paddingLeft={1}>{renderSubTitle()}</Box>}
      </Box>
    </>
  );
};
