import React, { ReactElement, useState } from 'react';

import { Box } from '@material-ui/core';
import Axios from 'axios';
import FileDownload from 'js-file-download';
import { useRouter } from 'next/router';

import { Invoice } from '@e-vo/types';

import { InvoiceIcon } from './InvoiceIcon';
import { InvoiceMobileItem } from './InvoiceMobileItem';
import { Money, MoreMenu, MoreMenuItems } from '../../components';
import { useConfigContext, useUserContext } from '../../contexts';
import { useBaseDataStore, useHandleAxiosResponse } from '../../hooks';
import {
  FormatMessage,
  useVkwFormatMessage,
  VkwBaseTableCell,
  VkwDataTable,
  VkwDataTableFieldConfig,
  VkwIconChargingOutline,
  VkwIconDownload,
  VkwIconShare,
  VkwIconThreeDots,
  VkwRowAction,
} from '../../library';

const getTableFieldsConfig = (formatMessage: FormatMessage): VkwDataTableFieldConfig<Invoice>[] => [
  {
    active: true,
    converter: 'date',
    label: formatMessage('Date'),
    property: 'billing.date',
    sortColumn: 'billing.date',
  },
  {
    active: true,
    bold: true,
    label: formatMessage('InvoiceId'),
    property: 'reference',
    renderCustomCell: dataEntry => {
      return <VkwBaseTableCell key="reference">{dataEntry.reference.replace(/^0+/, '')}</VkwBaseTableCell>;
    },
  },
  {
    active: true,
    label: formatMessage('NetAmount'),
    property: 'netAmount',
    renderCustomCell: function renderNetAmount(dataEntry) {
      return (
        <VkwBaseTableCell key="netAmount">
          <Money value={dataEntry.netAmount} />
        </VkwBaseTableCell>
      );
    },
    sortColumn: 'netAmount',
  },
  {
    active: true,
    bold: true,
    label: formatMessage('GrossAmount'),
    property: 'grossAmount',
    renderCustomCell: function renderGrossAmount(dataEntry) {
      return (
        <VkwBaseTableCell key="grossAmount">
          <Money value={dataEntry.grossAmount} />
        </VkwBaseTableCell>
      );
    },
    sortColumn: 'grossAmount',
  },
];

export const Invoices = (): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const [showMoreMenuConfig, setShowMoreMenuConfig] = useState<null | { invoice: Invoice; anchorEl: Element }>(null);
  const router = useRouter();
  const userContext = useUserContext();
  const configContext = useConfigContext();
  const handleAxiosResponse = useHandleAxiosResponse();

  const store = useBaseDataStore<Invoice>({
    defaultSortColumn: 'invoiceDate',
    defaultSortDirection: 'desc',
    url: `/api/next/v1/customers/${userContext.selectedCustomer?.id}/invoices`,
  });

  const getMoreMenuItems = (invoice: Invoice): MoreMenuItems => {
    const handleDownload = (): void => {
      setShowMoreMenuConfig(null);

      if (invoice.externalUrl) {
        window.location.href = invoice.externalUrl;
      } else if (invoice.fileToDownload) {
        const invoiceDownloadSource = invoice.source === 'CPO' ? 'c' : 'e';

        handleAxiosResponse(
          () =>
            Axios.get(
              `/api/next/v1/customers/${userContext.selectedCustomer?.id}/invoices/${invoice.id}/${invoiceDownloadSource}/download`,
              { responseType: 'blob' }
            ),
          {
            success: response => {
              FileDownload(response.data, `${invoice.id}.pdf`);
            },
          },
          { notFoundSnackbarMessage: formatMessage('NoDocumentAvailable') }
        );
      }
    };

    const moreMenuItems: MoreMenuItems = [];

    if (invoice.externalUrl || invoice.fileToDownload) {
      moreMenuItems.push({
        icon: <VkwIconDownload size={16} />,
        label: formatMessage('DownloadAsPDF'),
        onClick: handleDownload,
        type: 'item',
      });
    }

    if (configContext.featureFlags.enableTicketSystem) {
      moreMenuItems.push(
        {
          icon: <VkwIconShare size={16} />,
          label: formatMessage('SubmitTicket'),
          onClick: () => {
            router.push({
              pathname: `/support`,
              query: { description: `${formatMessage('InvoiceId')}: ${invoice.id}`, subject: 'other' },
            });
          },
          type: 'item',
        },
        {
          type: 'spacer',
        }
      );
    }

    if (
      userContext.account?.info.hasChargingTariffContract ||
      userContext.account?.info.hasSharedChargingTariffChargeDetailRecords
    ) {
      moreMenuItems.push({
        icon: <VkwIconChargingOutline size={16} />,
        label: formatMessage('MyChargeDetailRecords'),
        onClick: () =>
          router.push({
            pathname: '/charge-detail-records',
            query: {
              filters: JSON.stringify({
                chargeDateRange: [invoice.billing.periodFrom, invoice.billing.periodTo],
                invoiceId: [invoice.reference],
              }),
              view: 'chargingTariff',
            },
          }),
        type: 'item',
      });
    }

    if (
      userContext.account?.info.hasInfrastructureContract ||
      userContext.account?.info.hasSharedInfrastructureChargeDetailRecords
    ) {
      moreMenuItems.push({
        icon: <VkwIconChargingOutline size={16} />,
        label: formatMessage('ChargeDetailRecordsOnInfrastructure'),
        onClick: () =>
          router.push({
            pathname: '/charge-detail-records',
            query: {
              filters: JSON.stringify({
                chargeDateRange: [invoice.billing.periodFrom, invoice.billing.periodTo],
                invoiceId: [invoice.reference],
              }),
              view: 'infrastructure',
            },
          }),
        type: 'item',
      });
    }

    return moreMenuItems;
  };

  const getRowActions = (dataEntry: Invoice): VkwRowAction[] => {
    return [
      {
        icon: <VkwIconThreeDots />,
        onClick: event => setShowMoreMenuConfig({ anchorEl: event.currentTarget, invoice: dataEntry }),
      },
    ];
  };

  return (
    <Box marginTop={1}>
      <VkwDataTable
        initialized={store.initialized}
        loading={store.loading}
        loadAdditionalEntries={store.loadAdditionalEntries}
        page={store.page}
        pageSize={store.pageSize}
        pages={store.pages}
        data={store.data}
        totalRecords={store.totalRecords}
        hasMore={store.hasMore}
        setPage={store.setPage}
        setIsMobile={store.setIsMobile}
        tableFieldsConfig={getTableFieldsConfig(formatMessage)}
        noResultsDisplayText={formatMessage('NoResultsForInvoices')}
        noResultsWithActiveFilterDisplayText={formatMessage('NoResultsWithActiveFilter')}
        getRowActions={getRowActions}
        renderIcon={() => <InvoiceIcon />}
        renderMobileItem={(dataEntry, i) => (
          <InvoiceMobileItem
            key={i}
            invoice={dataEntry}
            onClick={event => setShowMoreMenuConfig({ anchorEl: event.currentTarget, invoice: dataEntry })}
          />
        )}
        filters={store.filters}
        filterOptions={store.filterOptions}
        onChangeFilter={store.changeFilter}
        onClearFilters={store.clearFilters}
      />
      {showMoreMenuConfig && (
        <MoreMenu
          anchorEl={showMoreMenuConfig.anchorEl}
          items={getMoreMenuItems(showMoreMenuConfig.invoice)}
          onClose={() => setShowMoreMenuConfig(null)}
        />
      )}
    </Box>
  );
};
