import React, { ReactElement } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { Invoice } from '@e-vo/types';

import { InvoiceIcon } from './InvoiceIcon';
import { Money } from '../../components';
import { VkwIconButton, VkwIconThreeDots, VkwTheme, dateDisplay, getVkwTypographyStyle } from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  amount: {
    ...getVkwTypographyStyle('h9', theme),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  date: {
    ...getVkwTypographyStyle('text14', theme),
  },
  invoiceId: {
    ...getVkwTypographyStyle('h9', theme),
  },
}));

interface InvoiceMobileItemProps {
  invoice: Invoice;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const InvoiceMobileItem = ({ invoice, onClick }: InvoiceMobileItemProps): ReactElement => {
  const styles = useStyles();

  return (
    <Box display="flex" alignItems="center" paddingY={2}>
      <div>
        <InvoiceIcon />
      </div>
      <Box paddingX={2} flexGrow={1}>
        <div className={styles.invoiceId}>{invoice.id}</div>
        <div className={styles.date}>{dateDisplay(invoice.date)}</div>
      </Box>
      <div className={styles.amount}>
        <Money value={invoice.grossAmount} />
      </div>
      <div>
        <VkwIconButton onClick={onClick}>
          <VkwIconThreeDots />
        </VkwIconButton>
      </div>
    </Box>
  );
};
