import React, { ReactElement } from 'react';

import { useConfigContext } from '../../contexts';

interface MoneyProps {
  value: number;
}

// LangSettings defines the max and min length of the digits
const langSettings = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
};

export const Money = ({ value }: MoneyProps): ReactElement => {
  const configContext = useConfigContext();

  const currencyValue = value.toLocaleString(configContext.config.locale, langSettings);

  return (
    <>
      {configContext.config.currency} {currencyValue}
    </>
  );
};
