import React, { ReactNode, useEffect, useState } from 'react';
import 'raf/polyfill';

import '../styles/globals.css';

import NiceModal from '@ebay/nice-modal-react';
import { Box, CssBaseline, makeStyles, ThemeProvider, Typography } from '@material-ui/core';
import { AxiosError } from 'axios';
import Bowser from 'bowser';
import { loadGetInitialProps } from 'next/dist/shared/lib/utils';
import { getToken } from 'next-auth/jwt';
import { SessionProvider } from 'next-auth/react';
import TagManager from 'react-gtm-module';
import { FormattedMessage } from 'react-intl';

import { MobilityProvider } from '@e-vo/mobility/providers';

import { CookieConsentModal, CookieConsentSettingsModalId } from '../components/CookieConsentModal';
import { CookieConsentPopup } from '../components/CookieConsentPopup';
import { Navigation } from '../containers';
import { Config } from '../contexts';
import { CacheStatusResponse } from '../contexts/UserContext/getDataCacheState';
import { processDataCacheState } from '../contexts/UserContext/processDataCacheState';
import { Providers } from '../globals/Providers';
import {
  configureVkwAxios,
  configureVkwForm,
  getVkwTypographyStyle,
  useIFrame,
  VkwIntlContextProvider,
  VkwTheme,
} from '../library';
import messages from '../messages';
import { handleGetInitialProps } from '../server/HandleGetInitialProps';
import { sendWebApiGetRequest } from '../server/WebApiRequester';
import { getTheme } from '../themes';
import { MyAppContext, MyAppInitialProps, MyAppProps } from '../types/next';
import { getEnvFeatureFlags, getEnvGoogleMapsApiKey, getEnvGTMKey, getEnvProject, getEnvUrls } from '../util/helpers';
import { darkMode } from '../util/tailwind';

const secret = process.env.NEXTAUTH_SECRET;

const useStyles = makeStyles<VkwTheme>(theme => ({
  unsupportedBrowserContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    width: '100vw',
  },
  unsupportedBrowserText: {
    ...getVkwTypographyStyle('text14', theme),
    maxWidth: '550px',
  },
  unsupportedBrowserTitle: {
    ...getVkwTypographyStyle('h4', theme),
    paddingBottom: '4px',
  },
}));

const MyApp = ({
  Component,
  account,
  backofficeUrl,
  config,
  customers,
  dataCacheState,
  envUrls,
  featureFlags,
  googleMapsApiKey,
  gtmKey,
  pageProps,
  project,
  selectedCustomer,
  unsupportedBrowser,
  userPanelLinks,
}: MyAppProps): ReactNode => {
  const styles = useStyles();
  const isIFrame = useIFrame();

  const [state] = useState(() => {
    if (!config) {
      throw Error('No config given');
    }

    if (!project || !envUrls || !featureFlags || !googleMapsApiKey) {
      throw new Error('Set env variables for _app.tsx properly');
    }

    return {
      backofficeUrl,
      config,
      dataCacheState,
      envUrls,
      featureFlags,
      googleMapsApiKey,
      project,
      unsupportedBrowser,
      userPanelLinks,
    };
  });

  configureVkwAxios(state.envUrls.baseUrl);
  configureVkwForm();

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    if (gtmKey) {
      TagManager.initialize({ gtmId: gtmKey });
    }

    // Enable tailwind dark mode based on user theme palette
    darkMode(account ? account.info.theme === 'dark' : false);
  }, []);

  if (state.featureFlags.enableCookieConsentManager) {
    NiceModal.register(CookieConsentSettingsModalId, CookieConsentModal);
  }

  if (state.unsupportedBrowser) {
    const locale = 'de';
    const theme = getTheme('default', state.project);

    return (
      <>
        <style>{`
          body {
            display: flex;
          }
        `}</style>
        <VkwIntlContextProvider initLocale={locale} additionalMessages={messages}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box className={styles.unsupportedBrowserContainer}>
              <Typography className={styles.unsupportedBrowserTitle}>
                <FormattedMessage id="UnsupportedTitle" />
              </Typography>
              <Typography className={styles.unsupportedBrowserText}>
                <FormattedMessage id="UnsupportedMessage" />
              </Typography>
            </Box>
          </ThemeProvider>
        </VkwIntlContextProvider>
      </>
    );
  }
  return (
    <SessionProvider session={pageProps.session} refetchInterval={0}>
      <MobilityProvider>
        <Providers
          config={state.config}
          account={account}
          customers={customers}
          selectedCustomer={selectedCustomer}
          project={state.project}
          envUrls={state.envUrls}
          userPanelLinks={state.userPanelLinks}
          featureFlags={state.featureFlags}
          googleMapsApiKey={state.googleMapsApiKey}
          backofficeUrl={state.backofficeUrl}
          dataCacheState={state.dataCacheState}
        >
          {!Component.hideNavigation && (
            <Navigation hideNavigationBar={Component.hideNavigationBar || isIFrame}>
              <Component {...pageProps} />
            </Navigation>
          )}
          {Component.hideNavigation && <Component {...pageProps} />}
          {state.featureFlags.enableCookieConsentManager && <CookieConsentPopup />}
        </Providers>
      </MobilityProvider>
    </SessionProvider>
  );
};

async function getInitialProps({ Component, ctx }: MyAppContext): Promise<MyAppInitialProps> {
  const props: MyAppInitialProps = {
    account: null,
    backofficeUrl: null,
    config: null,
    customers: [],
    dataCacheState: null,
    envUrls: null,
    featureFlags: null,
    googleMapsApiKey: null,
    gtmKey: null,
    pageProps: await loadGetInitialProps(Component, ctx),
    project: null,
    selectedCustomer: null,
    unsupportedBrowser: false,
    userPanelLinks: null,
  };

  if (ctx.req && ctx.res) {
    const { asPath, req, res } = ctx;

    // Read .env Variables
    props.project = getEnvProject();
    props.envUrls = getEnvUrls();
    props.gtmKey = getEnvGTMKey();
    props.featureFlags = getEnvFeatureFlags();
    props.googleMapsApiKey = getEnvGoogleMapsApiKey();

    // Check unsupported Browser
    const userAgent = req.headers['user-agent'];

    if (!!userAgent && userAgent.length > 0) {
      const browser = Bowser.getParser(userAgent);

      const isUnsupportedBrowser = browser.satisfies({
        'internet explorer': '<=11',
      });

      props.unsupportedBrowser = isUnsupportedBrowser ?? false;
    }

    // Fetch config from API
    const axiosConfigResponse = await sendWebApiGetRequest<Config>('api/v1/config');

    if (axiosConfigResponse.status === 200) {
      props.config = axiosConfigResponse.data;
    }

    const token = await getToken({ req, secret });
    const accessToken = await getToken({ raw: true, req, secret });

    const { account, backofficeUrl, customers, selectedCustomer, userPanelLinks } = await handleGetInitialProps(
      req,
      res,
      asPath,
      token,
      accessToken
    );

    try {
      // Fetch dataCacheState from API
      const axiosDataCacheStateResponse = await sendWebApiGetRequest<CacheStatusResponse>(
        'api/v1/userInfo/CacheStatus',
        accessToken,
        { customerId: selectedCustomer?.id }
      );

      if (axiosDataCacheStateResponse.status === 200) {
        props.dataCacheState = processDataCacheState(axiosDataCacheStateResponse.data);
      }
    } catch (error) {
      const e = error as AxiosError;

      if (token) {
        // eslint-disable-next-line no-console
        console.warn(`Can´t fetch DataCacheState: Status ${e.response?.status}; Message ${e.response?.statusText}`);
      }
    }

    props.account = account;
    props.customers = customers;
    props.selectedCustomer = selectedCustomer;
    props.backofficeUrl = backofficeUrl;
    props.userPanelLinks = userPanelLinks;
  }

  return props;
}

MyApp.getInitialProps = getInitialProps;

export default MyApp;
